
import { defineComponent, ref } from 'vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { useLoading } from '@/core/hooks/use-loading';
import { swalErrNotification } from '@/core/helpers/utils';
import { IBienBanGiaiTheResponseBase, ICapSuaChuaResponseBase, IVatTuResponseBaseWithApprovedStatus } from '@/core/interfaces/ApiResponses';
import { BienBanGiaiTheService } from '@/core/services/BienBanGiaiThe.service';
import { useDateTime } from '@/core/hooks/use-date-time-hook';

import AddVatTuTableItems from '@/components/add-vat-tu-items-v2/add-vat-tu-table-items/AddVatTuTableItems.vue';
import BienBanGiaiThePrintModal from './bien-ban-giai-the-print-modal/BienBanGiaiThePrintModal.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import DinhMucCoDinhSection from '@/components/dinh-muc-co-dinh-section/DinhMucCoDinhSection.vue';
import DivCol from '@/layout/_shared/DivCol.vue';
import DivRow from '@/layout/_shared/DivRow.vue';
import LabelCustom from '@/components/shared/label-custom/LabelCustom.vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import PhieuXuatKhoTable from '@/views/crafted/pages/receipts/phieu-xuat-kho-table/PhieuXuatKhoTable.vue';
import ConfirmHoanThanh
  from "@/views/crafted/pages/bien-ban-giai-the/confirm-hoan-thanh-bien-ban-modal/ConfirmHoanThanh.vue";

export default defineComponent({
  name: 'bien-ban-giai-the-detail',

  components: {
    AddVatTuTableItems,
    BienBanGiaiThePrintModal,
    ButtonCustom,
    ConfirmHoanThanh,
    DinhMucCoDinhSection,
    DivCol,
    DivRow,
    LabelCustom,
    PageRowLayout,
    PhieuXuatKhoTable,
  },

  setup() {
    const { goBack, push, id: idBienBan } = useRouterCustom();
    const { ButtonTypeColors, ButtonsType } = useButtonCustom();
    const { endLoading, startLoading } = useLoading();
    const { moment } = useDateTime();

    const bienBanGiaiThePrintModalRef = ref();

    const onPrintButtonClicked = () => {
      bienBanGiaiThePrintModalRef.value.isOpen = true;
    };

    return {
      goBack, push,
      idBienBan,
      ButtonTypeColors, ButtonsType,
      endLoading, startLoading,
      moment,
      bienBanGiaiThePrintModalRef,
      onPrintButtonClicked,
    };
  },

  data() {
    return {
      bienBanDetail: null as IBienBanGiaiTheResponseBase | null,
      bienBanParentDetail: null as IBienBanGiaiTheResponseBase | null,
      capSuaChuaDetail: null as ICapSuaChuaResponseBase | null,
      confirmHoanThanhBienBan: false,
    };
  },

  mounted() {
    setCurrentPageTitle('Biên bản giải thể');

    this.fetchBienBan();
  },

  computed: {
    vatTuItemsDisplay(): IVatTuResponseBaseWithApprovedStatus[] {
      if (!this.bienBanDetail) return [];

      if (this.bienBanDetail.ngoai_dinh_muc?.length >= 0) {
        return this.bienBanDetail.ngoai_dinh_muc.map(vatTuItem => ({
          ...vatTuItem,
          ...(vatTuItem.pivot.ghi_chu && {
            ghi_chu: vatTuItem.pivot.ghi_chu,
          }),
          is_approved: vatTuItem.pivot.is_approved === 1,
          so_luong: vatTuItem.pivot.so_luong,
        }));
      }

      return [];
    },
    idCapSuaChua() {
      return this.bienBanDetail?.ke_hoach_sua_chua?.id_cap_sc || this.bienBanDetail?.cap_sua_chua?.id || 0;
    },
    idDanhMucToaXe() {
      return this.bienBanDetail?.ke_hoach_sua_chua?.id_danh_muc_toa_xe || this.bienBanDetail?.toa_xe.danh_muc_toa_xe?.[0].id || 0;
    }
  },

  methods: {
    async fetchBienBan() {
      await this.startLoading();

      try {
        const { data: { data } } = await BienBanGiaiTheService.get(this.idBienBan);
        this.bienBanDetail = data;

        if (this.bienBanDetail.id_cha) {
          const { data: { data: parentData } } = await BienBanGiaiTheService.get(this.bienBanDetail.id_cha);

          this.bienBanParentDetail = parentData;
        }

        await this.endLoading();
      } catch (e) {
        this.endLoading();
        await swalErrNotification(e, 'Có lỗi khi lấy thông tin biên bản');
      }
    },

    onClosePrintModal() {
      if (this.bienBanGiaiThePrintModalRef) {
        this.bienBanGiaiThePrintModalRef.isOpen = false;
      }
    },

    async hoanThanhHander () {
      this.confirmHoanThanhBienBan = false;

      await this.fetchBienBan();
    }
  },
});
